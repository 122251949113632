<template lang="">
  <div>
    <h6 class="heading-small text-muted mb-4">จัดการปัญหา</h6>
    <div class="pl-lg-4">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <div class="mb-4">
                <label class="form-label">รหัสการสั่งซื้อ</label>
                <base-input
                  name="order_id"
                  placeholder="รหัสการสั่งซื้อ"
                  v-model="item.order_code"
                  disabled
                ></base-input>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <label class="form-label">หัวข้อ</label>
                <base-input
                  name="topic"
                  placeholder="หัวข้อ"
                  v-model="item.topic"
                  :rules="{ required: true }"
                ></base-input>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <label class="form-label">ประเภทปัญหา</label>
                <select
                  class="form-control"
                  v-model="item.issue_type_id"
                  :rules="{ required: true }"
                >
                  <option
                    v-for="data in issue_types"
                    :key="`issue_list_option_${data.id}`"
                    :value="data.id"
                  >
                    {{ data.text }}
                  </option>
                </select>
                <base-input
                  name="issue_type_id"
                  v-model="item.issue_type_id"
                  :rules="{ required: true }"
                  class="custom-input-valid-datepicker"
                ></base-input>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <label class="form-label">ผู้ออกผู้ใช้</label>
                <select
                  class="form-control"
                  v-model="item.user_issuer"
                  :rules="{ required: true }"
                >
                  <option
                    v-for="data in user_issuers"
                    :key="`issuer_list_option_${data.id}`"
                    :value="data.id"
                  >
                    {{ data.fullname }}
                  </option>
                </select>
                <base-input
                  name="user_issuer"
                  v-model="item.user_issuer"
                  :rules="{ required: true }"
                  class="custom-input-valid-datepicker"
                ></base-input>
              </div>
            </div>

            <div class="col-md-4">
              <div class="mb-4">
                <label class="form-label">ความสำคัญ</label>
                <b-form-radio-group
                  v-model="item.priority"
                  :options="options"
                  class="mt-2"
                  name="priority"
                  text-field="text"
                ></b-form-radio-group>
                <base-input
                  name="priority"
                  v-model="item.priority"
                  :rules="{ required: true }"
                  class="custom-input-valid-datepicker"
                ></base-input>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                <label class="col-form-label">หมายเหตุ</label>
                <div class="">
                  <b-form-textarea
                    name="remark"
                    placeholder="หมายเหตุ"
                    v-model="item.remark"
                    rows="3"
                    max-rows="5"
                  ></b-form-textarea>
                  <base-input
                    name="remark"
                    v-model="item.remark"
                    :rules="{ required: true }"
                    class="custom-input-valid-datepicker"
                  ></base-input>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                <label class="col-form-label">รูปภาพ</label>
                <div
                  class="card"
                  style="border: 1px solid #cad1d7; border-radius: 5px"
                >
                  <div
                    class="card-body m-auto"
                    style="height: 230px; overflow: auto"
                  >
                    <input
                      type="file"
                      id="fileUpload"
                      accept="image/*"
                      style="display: none"
                      @change="uploadFileChangeHandler"
                      multiple
                      name="imgs[]"
                    />
                    <span
                      class="thumbnail"
                      v-for="(img, index) in displayImgs"
                      :key="`display_image_${index}`"
                    >
                      <div class="action-delete">
                        <a
                          href="javascript:void(0)"
                          @click="deleteImageHandler(img, index)"
                        >
                          <i class="fa fa-times"></i>
                        </a>
                      </div>
                      <img
                        :src="img ? `${img.url || img}` : noImageData"
                        class="img-thumbnail"
                        style="max-width: 180px"
                        @click="uploadFileHandler()"
                      />
                    </span>
                    <img
                      v-if="!displayImgs.length"
                      :src="noImageData"
                      class
                      style="max-width: 180px"
                      @click="uploadFileHandler()"
                    />
                  </div>
                </div>
                <div
                  class="invalid-feedback"
                  style="display: block"
                  v-if="!displayImgs.length"
                >
                  The priority field is required
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                <label class="col-form-label">การแก้ไขปัญหา</label>
                <div class="">
                  <b-form-textarea
                    name="remark"
                    placeholder="การแก้ไขปัญหา"
                    v-model="item.solution"
                    rows="3"
                    max-rows="5"
                  ></b-form-textarea>
                  <base-input
                    name="solution"
                    v-model="item.solution"
                    :rules="{ required: true }"
                    class="custom-input-valid-datepicker"
                  ></base-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-4" />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import Constants from "../../../util/constants";
export default {
  name: "product-form-product",
  data() {
    return {
      issue_types: [],
      user_issuers: [],
      options: Constants.issue_priority,
      noImageData: Constants.noImageData,
      imgs: [],
      displayImg: "",
      displayImgs: [],
      vm: this,
    };
  },
  props: ["item", "images", "imageDeletes"],
  methods: {
    async getIssueType() {
      this.issue_types = await this.HttpServices.getMasterData(
        `/master/getIssueTypes`
      );
    },
    async getUser() {
      let params = `page=${1}&limit=${1000000}&ascDesc=asc`;
      const resultUsers = await this.HttpServices.getData(
        `/ab_users?${params}`
      );
      if (resultUsers.status.code == 200 && resultUsers.data.data.length > 0) {
        // this.item.issue_type_id = this.issue_types[0].id;
        this.user_issuers = resultUsers.data.data;
      }
    },
    uploadFileHandler() {
      $("#fileUpload").trigger("click");
    },
    uploadFileChangeHandler(event) {
      const vm = this
      let img = event.target.files;
      if (img.length > 0) {
        for (let i = 0; i < img.length; i++) {
          var reader = new FileReader();
          reader.onload = (event) => {
            vm.displayImgs.push(event.target.result);
          };
          reader.readAsDataURL(img[i]);
          vm.item.imageDisplays.push(img[i]);
          vm.item.images.push(img[i]);
        }

        vm.imgs = img;
      } else {
        this.displayImg = null;
        this.file = null;
        this.imgs = [];
        this.displayImgs = [];
      }
    },
    deleteImageHandler(img, index) {
      const image = this.item.imageDisplays[index];
      if (image.id != 0) {
        this.imageDeletes.push(image.id);
        if (image.id_t) {
          this.imageDeletes.push(image.id_t);
        }
        this.item.imageDisplays.splice(index, 1);
      } else {
        let cnt = this.item.imageDisplays.filter((v) => v.id != 0).length;
        this.item.imageDisplays.splice(index - cnt, 1);
      }
      this.displayImgs.splice(index, 1);
    },
  },
  async mounted() {
    this.getIssueType();
    this.getUser();
  },
  watch: {
    images(newVal, oldVal) {
      if (newVal) {
        this.imgs = newVal;
        this.displayImgs = newVal;
      }
    },
  },
};
</script>
