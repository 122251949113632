<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header">
            <b-col cols="8">
              <h2 class="mb-0"><i class="fa fa-pen"></i> แก้ไขจัดการปัญหา</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(editHandler)">
              <form-issue
                :item="item"
                :images="images"
                :imageDeletes="imageDeletes"
              />
              <div class="float-right d-flex">
                <span
                  ><router-link :to="'/issue'" class="nav-link text-underline">
                    กลับ</router-link
                  ></span
                >
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button
                  type="submit"
                  variant="primary"
                  class="style_btn"
                  v-if="permission.actionData.flag_update"
                  ><i class="fa fa-check"></i> บันทึก</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formIssue from "./form.issue";
import StringUtils from "../../../util/StringUtils";
export default {
  name: "issue-view-page-issue-edit",
  data() {
    return {
      permission: {
        appSlug: "issue",
        actionData: {}
      },
      item: {},
      id: "",
      images: [],
      imageDeletes: []
    };
  },
  methods: {
    async editHandler() {
      const file = this.item.images;
      delete this.item.file;
      const obj = {
        issue_type_id: this.item.issue_type_id,
        topic: this.item.topic,
        remark: this.item.remark,
        priority: this.item.priority,
        solution: this.item.solution,
        user_issuer: this.item.user_issuer
      };
      const result = await this.HttpServices.putData(
        `/ab_issue/updateSolve/${this.item.id}`,
        obj
      );
      if (result && result.status.code == "200") {
        const s3Auth = await this.HttpServices.authenS3();
        const medias = [];
        if (file && file.length > 0) {
          if (s3Auth._info) {
            const accessToken = s3Auth._info.secret.accessToken;
            for (const f of file) {
              if (f instanceof File) {
                let params = new FormData();
                params.append("file", f);
                params.append("title", f.name);
                params.append("file_category", "order-payment-slip");
                const resultMedia = await this.HttpServices.postFormDataS3(
                  params,
                  accessToken
                );
                if (resultMedia.media && resultMedia.media.length > 0) {
                  const media = resultMedia.media[0];
                  const mediaUpload = {
                    id: media.id,
                    original_name: media.original_name,
                    mime_type: media.mime_type,
                    size: media.size,
                    url: media.url
                  };

                  params = new FormData();
                  const thumbFile = await StringUtils.resizeBlobToFile({
                    file: f,
                    maxSize: 200
                  });
                  params.append("file", thumbFile);
                  params.append("title", thumbFile.name);
                  params.append("file_category", "order-payment-slip");
                  let resultThumb = await this.HttpServices.postFormDataS3(
                    params,
                    accessToken
                  );
                  if (resultThumb.media && resultThumb.media.length > 0) {
                    const mediaThumb = resultThumb.media[0];
                    mediaUpload.id_t = mediaThumb.id;
                    mediaUpload.original_name_t = mediaThumb.original_name;
                    mediaUpload.mime_type_t = mediaThumb.mime_type;
                    mediaUpload.size_t = mediaThumb.size;
                    mediaUpload.url_t = mediaThumb.url;
                  }
                  medias.push(mediaUpload);
                }
              } else {
                const mediaUpload = {
                  id: f.id,
                  original_name: f.original_name,
                  mime_type: f.mime_type,
                  size: f.size,
                  url: f.url
                };
                if (f.id_t) {
                  mediaUpload.id_t = f.id_t;
                  mediaUpload.original_name_t = f.original_name_t;
                  mediaUpload.mime_type_t = f.mime_type_t;
                  mediaUpload.size_t = f.size_t;
                  mediaUpload.url_t = f.url_t;
                }
                medias.push(mediaUpload);
              }
            }
          }
          if (medias.length > 0) {
            const params = {
              issue_type_id: this.item.issue_type_id,
              topic: this.item.topic,
              remark: this.item.remark,
              priority: this.item.priority,
              solution: this.item.solution,
              images: JSON.stringify(medias),
              media: JSON.stringify(medias)
            };
            await this.HttpServices.postFormData(
              `/ab_issue/upload/${this.item.id}`,
              params
            );
            await this.HttpServices.putData(
              `/ab_issue/updateSolve/${this.item.id}`,
              params
            );
          }
        }
        if (s3Auth._info) {
          const accessToken = s3Auth._info.secret.accessToken;
          if (this.imageDeletes && this.imageDeletes.length > 0) {
            for (const v of this.imageDeletes) {
              await this.HttpServices.deleteDataS3(v, accessToken);
            }
          }
        }
        this.AlertUtils.alertCallback("success", `บันทึกสำเร็จ`, () => {
          this.$router.push("/issue");
        });
      } else {
        this.AlertUtils.alert("warning", result.status.message);
      }
    },
    onBackHandler() {
      this.$router.push("/issue");
    },

    async getItem() {
      const result = await this.HttpServices.getData(`/ab_issue/${this.id}`);
      if (result && result.status.code == "200") {
        this.item = result.data;

        this.item.imageDisplays = this.item.images
          ? JSON.parse(this.item.images)
          : [];
        this.images = this.item.images ? JSON.parse(this.item.images) : [];

        this.item.deletes = [];
      } else {
        this.AlertUtils.alertCallback("warning", `ไม่พบข้อมูลปัญหา`, () => {
          this.$router.push("/issue");
        });
      }
    }
  },
  components: {
    formIssue
  },
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );
    this.id = await this.SessionStorageUtils.getSession("page-issue");
    await this.getItem();
  }
};
</script>
